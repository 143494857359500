.avatar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  .avatar-img {
    width: 58%;
    height: 58%;
  }
}
