.top_ad {
  z-index: 99999;
  background: #f8f8f8;

  background-repeat: no-repeat;
  background-size: 100% auto;

  min-height: 60px;
  height: auto;
  width: 100vw;

  position: fixed;
  top: 0px;
  left: 0px;

  display: flex;
  justify-content: space-between;

  &_left {
    align-items: center;
    justify-content: center;

    float: left;
    // margin: 15px 8px 15px 16px;
    margin: auto 8px auto 16px;

    img {
      width: 28px;
      height: 28px;
    }
  }

  &_middle {
    float: left;
    font-size: 14px;

    width: calc(100% - 120px);

    font-weight: 600;
    color: #232625;
    line-height: 24px;
    padding: 18px 0px;
  }

  &_right {
    float: right;

    button {
      cursor: pointer;
      background: #fff;

      width: 60px;
      height: 30px;
      color: #1dd2c1;
      font-weight: 600;
      border-radius: 8px;

      font-size: 14px;

      margin-top: 14px;
      margin-right: 16px;

      border: none;
      padding: 0px;
    }

    button:hover {
      background: #61dfd3;
    }
  }
}
