.v17 {
  max-width: 800px;
  width: 100%;
  min-height: 440px;
  height: max-content;
  background-color: #fff;
  border-radius: 8px;
  padding: 50px 0px 68px;
  position: relative;

  &_center {
    @media screen and (max-device-width: 750px) {
      padding: 21px 0px;
    }

    margin: 0 auto;
    max-width: 590px;
    padding: 0 14px;

    width: 100%;
    background-color: #fff;

    &_content {
      width: 528px;
      margin: 0 auto;

      // 标题
      &_title {
        font-size: 20px;
        font-weight: 500;
        color: #060606;

        // 不含背景图的底部间距
        margin-bottom: 20px;
      }

      // 标题
      &_title__margin0 {
        font-size: 20px;
        font-weight: 500;
        color: #060606;
        line-height: 28px;

        // 不含背景图的底部间距
        margin-bottom: 2px;
      }
    }
  }

  &_footer {
    margin-top: 24px;
    padding: 0 20px;

    // left: calc(50vw - 210px);
    // left: calc(50vw - 280px);
    // top: 90vh;
    text-align: center;
    z-index: 99;

    @media screen and (max-device-width: 776px) {
      margin-top: 40px;
    }
  }
}

.waitBtn {
  width: 240px;
  height: 40px;
  background: linear-gradient(32deg, #00E09D 0%, #1DD2C1 100%);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 21px;
  cursor: default;
}
.rejectBtn {
  font-size: 16px;
  font-weight: 500;
  color: #CBCBCB;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.ant-modal-body {
  padding: 0;
}

.ant-modal-mask {
  background: rgba(6, 6, 6, 0.3) !important;
}

.ant-modal-content {
  border-radius: 6px !important;
}

.ant-modal {
  padding: 0 !important;
}

.ant-popover-inner {
  border-radius: 5px !important;
}

.ant-popover-arrow {
  display: none;
}

.fx-split-char {
  margin: 0px 9px;
  border-left: 1px solid rgba(6, 6, 6, 0.2);
  height: 9px;
  width: 1px;
}

.btn_fixed {
  // position: fixed;
  // width: calc(100vw - 20px);
  width: calc(100% - 10px);
  margin: 20px 0px;
  bottom: 40px;
}
