.wx_back {
  background: linear-gradient(165deg, #eefbee 0%, #bdfef6 64%, #6bd8be 100%);
  width: 100vw;
  height: max-content;

  padding-bottom: 120px;
  display: block;

  &__inner {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;

    &__bannerpic {
      width: 100%;
      min-height: 460px;
    }

    &__layer {
      // max-width: 88%;
      width: 88%;

      height: 315px;
      background: #ffffff;
      border-radius: 17px;

      display: block;

      text-align: center;

      margin: 0 auto;
    }
  }
}

.info {
  &_title {
    padding: 18px 0 10px 0;
  }

  &_2dcode {
    img {
      width: 27%;
      height: auto;
    }
  }

  &_help {
    // font-size: 18px;
    font-size: 22px;
    // font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #00c8b7;
    line-height: 25px;
    margin-bottom: 17px;
  }

  &_line {
    display: block;
    width: 100%;
    height: 12.96%;
    margin-bottom: 21px;

    &_icon {
      width: 50%;
      padding-right: 15px;
      float: left;
      height: 100%;

      &_left_div {
        float: right;
        height: 100%;

        display: flex;
        align-items: center;
        padding: 0px 0px 0px 18px;
        background: #fff8ee;
        border-radius: 19px 0px 0px 19px;
        position: relative;
        z-index: 1;
      }

      &_right_pic {
        float: right;
        height: 100%;
        min-width: 34px;
        z-index: 2;

        background: #fff8ee;

        border-radius: 0px 50% 50% 0px;
      }
    }

    &_icon2 {
      width: 50%;
      padding-left: 15px;
      float: left;
      height: 100%;

      &_left_div {
        float: left;
        height: 100%;

        display: flex;
        align-items: center;
        padding: 0px 18px 0px 0px;
        background: #fff8ee;
        border-radius: 0px 19px 19px 0px;
        position: relative;
        z-index: 1;
      }

      &_right_pic {
        float: left;
        height: 100%;
        z-index: 2;

        background: #fff8ee;

        border-radius: 50% 0px 0px 50%;
      }
    }
  }
}
