

.info{
  padding-left: 12px;
  padding-right: 12px;

  &__title{
    font-weight: 500;
    font-size: 18px;
    color: #252525;
    margin-bottom: 20px;
  }
}