.login {
  height: 100vh;
  padding-top: 40px;
  display: flex;
  flex-direction: column;

  &__banner {
    width: 218.9px;
    height: 139.68px;
    margin: 0 auto 20px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .success{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .icon{
      color: #1DD2C1;
      width: 150px;
      height: 150px;
    }
    .text{
      font-size: large;
      font-weight: 500;
      margin-top: 36px;
    }
  }
}

.btn {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #1dd2c1;
  color: #fff;
  border-radius: 10px;
}
