

.form{
  margin-bottom: 80px;

  &__wrapper{

  }

  &__input{
    box-sizing: border-box;
    // height: 36px;
    // line-height: 36px;
    padding-right: 16px;
  }
}