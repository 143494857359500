.accountErrorRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 200px;
  position: relative;

  .imgBox {
    width: 54px;
    height: 54px;
    margin-top: 150px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    margin-top: 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #363636;
  }
}