.footerBtnRoot {
  display: flex;
  padding: 0 24px;
  justify-content: space-between;
  margin: 45px auto;
  .baseBtn {
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 21px;
  }
  .acceptBtn {
    width: 202px;
    background: #1DD2C1;
  }
  .rejectBtn {
    width: 117px;
    background: #FE4D4D;
  }

  .wxAccessBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    wx-open-launch-weapp {
      height: 48px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 15px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 21px;
      width: 202px;
      background: #1DD2C1;
    }
  }

  .wxRefuseBtn {
    wx-open-launch-weapp {
      height: 48px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 15px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 21px;
      width: 117px;
      background: #FE4D4D;
    }
  }
}

.footer-btn-task {
  position: fixed;
  bottom: 42px;
  min-width: 45%;
}

.footer-btn{
  width: 352px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  text-align: center;
  background: #1DD2C1;
  border-radius: 8px;
}