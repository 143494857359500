.avatarBoxRoot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 40px;
  }
  .userName {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #262626;
    margin-top: 8px;
    margin-bottom: 4px;
  }
  .desc {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #8F8F8F;
    margin-bottom: 40px;
  }
}

img {
  width: 100%;
  height: 100%;
}