.v17pc {
  margin: 0 auto;

  max-width: 800px;
  width: 100%;
  height: max-content;

  border-radius: 8px;
  padding: 30px 0px;

  @media screen and (max-width: 750px) {
    padding: 0px 0px;
  }

  &_center {
    background: #fff;

    padding: 59px 0 337px 0;

    margin: 0 auto;
    max-width: 520px;
    // min-height: 929px;

    width: 100%;

    text-align: center;

    // 头像
    &_header {
      display: inline-block;
      text-align: center;
      width: 100%;

      img {
        width: 80px;
        height: 80px;
        border: 2px solid #f6f6f6;
        border-radius: 50%;

        margin: 0 auto;
      }
    }

    &_title {
      font-size: 26px;

      margin-top: 24px;

      font-weight: 500;
      color: #1dd2c1;
      line-height: 28px;
    }

    &_desc {
      font-size: 15px;

      margin-top: 20px;

      font-weight: 400;
      color: #060606;
      line-height: 23px;
    }

    /*
    
    */
    // 头像
    &_avatar {
      width: 100%;
      text-align: center;
      display: inline-block;

      //
      margin-bottom: 13px;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid #f6f6f6;
      }
    }

    // 昵称
    &_nickname {
      font-size: 20px;
      font-weight: 400;
      color: #262626;
      line-height: 28px;

      text-align: center;

      margin-bottom: 11px;
    }

    // 文本
    &_text {
      font-size: 24px;

      font-weight: 600;
      color: #12141d;
      line-height: 33px;

      margin-bottom: 54px;

      text-align: center;

      span {
        color: #1abfaf;
      }
    }

    // 内容体
    &_content {
      width: 100%;
      background: #e0fcfa;
      border-radius: 23px;

      min-height: 214px;
      display: inline-block;

      margin-bottom: 122px;

      &_title__img {
        // text-align: center;
        width: 100%;
        display: inline-block;
        position: relative;
        top: -5px;

        &__banner {
          width: 268px;
          height: 40px;
          background: linear-gradient(45deg, #1dd2c1 0%, #00e09d 100%);
          border-radius: 0px 0px 21px 21px;

          margin: 0 auto;

          color: #fff;
          text-align: center;

          padding: 5px 22px 7px 22px;

          &__left {
            display: inline;
            position: absolute;
            top: 0px;
            left: calc(50% - 134px - 2.53px);

            width: 2.45px;
            height: 5.06px;

            border-top: 5.06px solid transparent;
            // border-bottom: 50px solid transparent;
            border-right: 2.45px solid #40a199;
          }

          &__right {
            display: inline;
            position: absolute;
            top: 0px;
            right: calc(50% - 134px - 2.53px);

            width: 2.45px;
            height: 5.06px;

            border-top: 5.06px solid transparent;
            // border-bottom: 50px solid transparent;
            border-left: 2.45px solid #40a199;
          }
        }
      }

      &_pics {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;

        padding-top: 33px;

        &_item {
          width: 33%;

          img {
            width: 60px;
            height: 60px;
            margin: 0 auto 18px;

            display: block;
          }

          div {
            font-size: 14px;

            font-weight: 400;
            color: #262626;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.btn {
  width: 100%;
  padding: 11px 0px 11px 0px;
  text-align: center;

  user-select: none;
  cursor: pointer;

  background: linear-gradient(45deg, #1dd2c1 0%, #00e09d 100%);
  border-radius: 8px;

  font-size: 20px;

  font-weight: 500;
  color: #ffffff;
  line-height: 28px;

  &:hover {
    opacity: 0.7;
    background: linear-gradient(45deg, #1dd2c1 0%, #00e09d 100%);
  }
}


// .fx-layout-anser {
//   width: 100%;
//   text-align: center;
//   .header {
//     width: 59px;
//     // width: 120px;
//     // height: 59px;
//     display: block;
//     margin: 0 auto;
//   }

//   .content {
//     display: block;
//     height: auto;
//     width: 100%;

//     .ans-title {
//       padding-top: 24px;
//       padding-bottom: 20px;

//       font-size: 26px;
//       font-weight: bolder;

//       .orange {
//         color: rgba(255, 172, 68, 1);
//       }

//       .red {
//         color: rgba(254, 77, 77, 1);
//       }

//       .theme {
//         color: rgba(29, 210, 193, 1);
//       }

//       .black {
//         color: #2f2f31;
//       }
//     }
//     .ans-result {
//       font-weight: bolder;
//       padding-bottom: 57px;
//       font-size: 15px;
//       opacity: 0.87;
//     }
//     .ans-from {
//       font-size: 15px;
//       opacity: 0.87;
//       padding-bottom: 2px;
//     }
//     .ans-task-name {
//       font-weight: bolder;
//       font-size: 15px;
//       opacity: 0.87;
//       padding-bottom: 2px;
//     }
//   }

//   .ad {
//     .info {
//       display: block;
//       padding-bottom: 28px;

//       font-size: 14px;
//       font-family: PingFangSC-Regular, PingFang SC;
//       font-weight: 400;
//       opacity: 0.45;
//       color: #060606;
//     }

//     .icon {
//       padding-bottom: 40px;

//       width: 80px;
//       height: 80px;
//       margin: 0 auto;
//       background: #ffffff;
//       box-shadow: 0px 1px 10px 0px rgba(6, 6, 6, 0.2);
//       border-radius: 21px;
//       img {
//         position: relative;
//         top: 10px;
//       }
//     }

//     .btn-download {
//       margin: 40px auto 0px;
//       width: 204px;
//     }

//     button {
//       width: 190px;
//     }
//   }
// }
