.titleRoot {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #262626;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .createText {
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #8F8F8F;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}