.summary-card-container {
  width: 310px;
  background-color: rgba(255, 254, 249, 1);
  border-radius: 10px;
  cursor: pointer;
  padding: 8px 12px;
  margin-bottom: 14px;
  position: relative;

  .card-icon {
    width: 55px;
    height: 25px;
    background: linear-gradient(45deg, #1dd2c1 0%, #00e09d 100%);
    border-radius: 0 14px 0 14px;
    color: #fff;
    text-align: center;
    line-height: 25px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .header {
    color: #8b8b8b;
    font-size: 12px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    margin-bottom: 10px;
    text-align: left;
  }

  .content {
    min-height: 37px;
    max-height: 180px;
    color: #060606;
    font-size: 13px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;
    text-align: left;

    .mask {
      width: 100%;
      height: 44px;
      background: linear-gradient(
        180deg,
        rgba(245, 255, 248, 0) 0%,
        #fffef9 59%,
        #fffef9 100%
      );
      color: #1dd2c1;
      font-size: 14px;
      // font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      text-align: center;
      position: absolute;
      bottom: 0;

      .text {
        width: 100%;
        position: absolute;
        bottom: 0;
      }
    }
  }

  .footer {
    display: flex;
    border-top: 1px dashed #dbdbdb;
    color: #8b8b8b;
    flex-direction: row-reverse;
    font-size: 12px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 12px;
    padding-top: 8px;

    img {
      width: 10px;
      height: 10px;
      position: relative;
      top: 1px;
      right: 4px;
    }
  }

  &.small {
    width: 100%;

    .content {
      position: static;
      max-height: 60px;
    }

    .mask {
      width: auto;
      height: auto;
      background: none;
      color: #919191;
      left: 12px;
      bottom: 7px;
      text-align: left;
    }

    .footer {
      border-top: none;
    }
  }
}
