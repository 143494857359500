.btn{
  width: 184px;
  height: 40px;
  font-size: 15px;
  color: #fff;
  background: linear-gradient(32deg, #00E09D 0%, #1DD2C1 100%);
  border-radius: 25px 5px 25px 5px;
  text-align: center;
  line-height: 40px;
  font-weight: 500;
}
