.item {
  position: relative;
  padding: 10px 6px 9px 6px;
  width: 100%;
  display: block;
  font-size: 15px;
  font-weight: 400;
  color: #060606;
  line-height: 21px;
  // background: #fff;
  border-radius: 5px;
  margin-top: 1px;

  &_right {
    position: absolute;
    // right: 6px;
    right: 0;
    top: 13px;
    font-size: 13px;
    font-weight: 400;
    color: #060606;
    line-height: 18px;

    // span {
    //   // margin-right: 44px;
    // }
  }
}

.line_active {
  padding: 3px 0;
  height: 7px;
  background: rgba(29, 210, 193, 1);
  display: block;
  width: 100%;
}

.line {
  padding: 3px 0;
  height: 7px;

  display: block;
  width: 100%;
}
