.bodyback {
  background: #effffe;
  width: 100vw;
  min-height: 100vh;
  height: max-content;
}

.v17pc {
  margin: 0 auto;
  background: #fff;

  max-width: 520px;
  width: 100%;
  height: max-content;
  min-height: 100vh;

  border-radius: 8px;
  padding: 69px 0px;

  &_center {
    background: #fff;

    margin: 0 auto;
    // max-width: 590px;
    max-width: 520px;
    // padding: 59px 73px 295px 73px;
    padding: 0px 73px 295px 73px;

    width: 100%;

    // 头像
    &_avatar {
      width: 100%;
      text-align: center;
      display: inline-block;

      //
      margin-bottom: 13px;

      img {
        // width: 60px;
        // height: 60px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 2px solid #f6f6f6;
      }
    }

    // 昵称
    &_nickname {
      font-size: 20px;
      font-weight: 400;
      color: #262626;
      line-height: 28px;

      text-align: center;

      margin-bottom: 11px;
    }

    // 文本
    &_text {
      font-size: 24px;

      font-weight: 600;
      color: #12141d;
      line-height: 33px;

      margin-bottom: 54px;

      text-align: center;

      span {
        color: #1abfaf;
      }
    }

    // 内容体
    &_content {
      width: 100%;
      background: #e0fcfa;
      border-radius: 23px;

      min-height: 214px;
      display: inline-block;

      margin-bottom: 122px;

      &_title__img {
        // text-align: center;
        width: 100%;
        display: inline-block;
        position: relative;
        top: -5px;

        &__banner {
          width: 268px;
          height: 40px;

          background: linear-gradient(45deg, #1dd2c1 0%, #00e09d 100%);
          border-radius: 0px 0px 21px 21px;

          margin: 0 auto;

          font-size: 20px;

          color: #fff;
          text-align: center;

          padding: 5px 22px 7px 22px;

          &__left {
            display: inline;
            position: absolute;
            top: 0px;
            left: calc(50% - 134px - 2px);

            width: 2.45px;
            height: 5.06px;

            border-top: 5.06px solid transparent;
            // border-bottom: 50px solid transparent;
            border-right: 2.45px solid #40a199;
          }

          &__right {
            display: inline;
            position: absolute;
            top: 0px;
            right: calc(50% - 134px - 2.53px);

            width: 2.45px;
            height: 5.06px;

            border-top: 5.06px solid transparent;
            // border-bottom: 50px solid transparent;
            border-left: 2.45px solid #40a199;
          }
        }
      }

      &_pics {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;

        padding-top: 33px;

        &_item {
          width: 33%;

          img {
            width: 60px;
            height: 60px;
            margin: 0 auto 18px;
            
            display: block;
          }

          div {
            font-size: 14px;

            font-weight: 400;
            color: #262626;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.v17 {
  margin: 0 auto;

  max-width: 800px;
  width: 100%;
  height: max-content;
  border-radius: 8px;
  padding: 0px 0px;

  &_center {
    margin: 0 auto;
    max-width: 590px;
    // padding: 59px 73px 295px 73px;
    padding: 21px 41px 115px 37px;

    width: 100%;
    // background-color: #effffe;

    &_avatar {
      width: 100%;
      text-align: center;
      display: inline-block;

      //
      margin-bottom: 13px;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid #f6f6f6;
      }
    }

    // 昵称
    &_nickname {
      font-size: 20px;
      font-weight: 400;
      color: #262626;
      line-height: 28px;

      text-align: center;

      margin-bottom: 11px;
    }

    // 文本
    &_text {
      font-size: 24px;

      font-weight: 600;
      color: #12141d;
      line-height: 33px;

      margin-bottom: 54px;

      text-align: center;

      span {
        color: #1abfaf;
      }
    }

    // 内容体
    &_content {
      width: 100%;
      background: #e0fcfa;
      border-radius: 23px;

      min-height: 214px;
      display: inline-block;

      margin-bottom: 122px;

      &_title__img {
        // text-align: center;
        width: 100%;
        display: inline-block;
        position: relative;
        top: -5px;

        &__banner {
          width: 268px;
          height: 40px;
          background: linear-gradient(45deg, #1dd2c1 0%, #00e09d 100%);
          border-radius: 0px 0px 21px 21px;

          margin: 0 auto;

          color: #fff;
          text-align: center;

          padding: 9px 22px 7px 22px;

          &__left {
            display: inline;
            position: absolute;
            top: 0px;
            left: calc(50% - 134px - 1.53px);

            width: 2.45px;
            height: 5.06px;

            border-top: 5.06px solid transparent;
            // border-bottom: 50px solid transparent;
            border-right: 2.45px solid #40a199;
          }

          &__right {
            display: inline;
            position: absolute;
            top: 0px;
            right: calc(50% - 134px - 2.53px);

            width: 2.45px;
            height: 5.06px;

            border-top: 5.06px solid transparent;
            // border-bottom: 50px solid transparent;
            border-left: 2.45px solid #40a199;
          }
        }
      }

      &_pics {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;

        padding-top: 33px;

        &_item {
          width: 33%;

          img {
            width: 60px;
            height: 60px;
            margin: 0px auto 18px;

            display: block;
          }

          div {
            font-size: 14px;

            font-weight: 400;
            color: #262626;
            line-height: 20px;
          }
        }
      }
    }
  }
}
