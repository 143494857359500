.taskBgInfoRoot {
  padding-bottom: 16px;
  .fileListBox {
    display: flex;
    flex-wrap: wrap;
  }
}
.textRow {
  font-size: 13px;
  line-height: 18px;
  color: #363636;
  margin-bottom: 16px;
  .title {
    font-weight: 600;
    color: #4C5F8A;
    margin-right: 2px;
  }
}