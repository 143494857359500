html, body {
    width: 100%;
    height: 100%;
}

html, body, p {
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    height: 100%;
    // background: url(../image/bg.jpg) no-repeat;
    // background-size: cover;
    // background-attachment: fixed;
    // background-position: top;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.logoContainer {
    width: 82px;
    height: 82px;
    border-radius: 18px;
    background-color: #fff;
    margin: 57px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoContainer .logo {
    width: 48px;
    height: 54px;
}

.appName, .declaration {
    color: white;
    text-align: center;
}

.appName {
    font-size: 24px;
    margin-top: 18px;
}

.declaration {
    font-size: 12px;
    margin-top: 4px;
}

.schedule {
    // width: 277px;
    width: 100%;
    // height: 564px;
    height: 700px;
    margin: 28px auto 0;
    // background: url(/public/image/schedule.png) no-repeat;
     background-size: cover;
}

.version{
    width: 343px;
    height: 160px;
    margin: 20px auto 120px auto;
}

.downloadContainer {
    width: 100%;
    height: 98px;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
}

.downloadContainer .filter {
    width: 100%;
    height: 98px;
    filter: blur(30px);
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.downloadButton {
    width: 120px;
    height: 40px;
    background: black;
    border-radius: 20px;
    color: white;
    font-size: 16px;
    margin: 0 5px;
}

.btnBox {
    width: 100%;
    height: 98px;
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.version-container{
    .version{
        width: 340px;
        height: 160px;
        background-color: rgb(128,238,227);
        border-radius: 15px;
        padding: 30px 40px;
        .v-header{
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            font-size: 16px;
            a,span{
                color:black;
            }
        }
        .company{
            margin-top:30px;
            margin-bottom: 6px;
            text-align: center;
            font-size: 14px;
            color: #363a39;
        }
        .v-code{
            text-align: center;
            font-size: 13px;
            color: #839695;
        }
    }
}