.fx-capsule {
  background: none;
  border: none;
  max-width: 100%;
  overflow-wrap: break-word;
  text-align: left;
  padding-left: 0;
  padding-right: 8px;
}

.fx-capsule:hover {
  background: none;
}

.fx-capsule-one {
  border-radius: 36px;
  border: 1px solid rgba(224, 224, 224, 1);
  // padding: 4px 10px;
  padding: 6px 14px 8px 14px;
  color: rgba(6, 6, 6, 0.87);
  width: auto;
  display: block;
  font-size: 14px;
  margin-bottom: 8px;

  span {
    position: relative;
    top: 1px;
  }

  &_gray {
    color: rgba(224, 224, 224, 1);
  }
}

.fx-capsule-one:hover {
  background: none;
}
