section{
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color:#f8f8f8;
}

.h5-pc-root {
  max-width: 800px;
  margin: 0 auto;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}

@media screen and (max-width: 750px){

  html {
    font-size: calc(100vw / 12);
  }
  .h5-pc-invite {
    background: #F4F4F4 !important;
    margin: 72px auto 0 auto !important;
  }
  .h5-pc-wxwork {
    margin-top: 0 !important;
  }
}


.h5-pc-invite {
  margin: 0 auto;
  max-width: 520px;
  width: 100%;
  height: calc(100% - 72px);
  background: #fff;

  .inner {
    display: block;
    width: 100%;
    overflow: hidden;
    max-width: 750px;
    margin: 0 auto;
    padding-top: 35px;
    padding-bottom: 120px;
  }

}


.invite-box {
  width: 100%;
  overflow: hidden;
  max-width: 428px;
  margin: 0 auto;
  padding: 50px 45px 0px 45px;
  display: flex;
  flex-direction: column;
  text-align: center;
  letter-spacing: 1px;

  .in-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
    margin-bottom: 30px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 60px;
    }

    p {
      font-size: 14px;
      color: rgba(180, 180, 180, 1);
    }
  }

  .in-body {
    .projectName {
      font-size: 26px;
      font-weight: 500;
      color: #262626;
      margin-bottom: 20px;
      line-height: 26px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }

    & > div {
      // max-height: 80px;
      font-size: 14px;
      font-weight: 400;
      color: #262626;
      line-height: 20px;
      text-align: left;
      // display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      margin-bottom: 30px;
    }
  }

  .in-footer {
    margin-top: 120px;
    display: flex;
    justify-content: center;
    // gap: 20px;

    .btn {
      width: 120px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid transparent;
      display: grid;
      place-items: center;
      cursor: pointer;
      font-size: 15px;
      font-weight: 400;
    }

    .cancel-btn {
      color: rgba(29, 210, 193, 1);
      border-color: rgba(29, 210, 193, 1);
      margin-left: 20px;
    }

    .confirm-btn {
      background: linear-gradient(32deg, #00e09d 0%, #1dd2c1 100%);
      color: whitesmoke;
    }
  }
}

.takers{
  width: 100%;
  height: 400px;
  display: grid;
  grid-template-columns: repeat(5, 30px);
  grid-template-rows: repeat(5, 30px);
  grid-gap: 13px;
  justify-content: center;
  justify-items: center;
  overflow-y: auto !important;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .alt{
    width: 30px;
    height: 30px;
    border-radius: 30px;
    overflow: hidden;
    opacity: 0.3;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .join{
    opacity: 1;
  }
}

.btn-list {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pc-page {
  max-width: 800px;
  height: 600px;
  .invite-box {
    padding: 50px 0 0;
  }
  .takers {
    height: 200px;
  }
}
