.corpFailurePage {
  width: 100vw;
  height: 100vh;
  background: #F8F8F8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .errorLogo {
    width: 300px;
    margin-bottom: 8px;

    img {
      width: 100%;
    }
  }

  .titleBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #262626;
      margin-bottom: 8px;
    }
    .desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #8F8F8F;
    }
  }
}