.chatFrameRoot {
  width: 100%;
  background: #FAFAFA;
  .chatItem {
    display: flex;
    margin-bottom: 26px;
    .avatarBox {
      width: 33px;
      height: 33px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .chatItemRight {
      width: calc(100% - 43px);
      .nickNameBox {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        .nickName {
          font-size: 12px;
          color: #060606;
        }
        .chatDate {
          font-size: 11px;
          color: #B4B4B4;
          margin-left: 8px;
          margin-right: 8px;
        }
        .important {
          width: 40px;
          height: 18px;
          text-align: center;
          background: rgba(254, 196, 66, 0.245957);
          font-weight: 500;
          font-size: 11px;
          color: #DC9B04;
          border-radius: 6px
        }
      }
      .chatContent {
        display: flex;
        flex-wrap: wrap;
        font-size: 15px;
        line-height: 21px;
        color: #060606;
        background: #FFFFFF;
        border-radius: 10px 10px 10px 1px;
        padding: 8px 12px;
      }
    }
  }
}