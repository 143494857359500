.inviteTargetRoot {
  width: 800px;
  background: #FFFFFF;
  border-radius: 8px;
  position: relative;

  .bgHeader {
    width: 100%;
    height: 249px;
    background-size: 100%;
    position: absolute;
    top: 0;
    background: linear-gradient(180deg, #D4EFFF 0%, rgba(255, 255, 255, 0.68) 100%);
  }

  .bgIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: 124px;
    height: 127px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    width: 430px;
    padding: 66px 0 84px;
    margin: 0 auto;
    position: relative;

    .title {
      color: #262626;
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 20px;
      text-align: center;
    }

    .invite-text {
      color: #1DD2C1;
    }

    .wait-text {
      color: #FFAC44;
    }

    .inviteInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      .avatarBox {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        margin-bottom: 12px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .name {
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #363636;
      }
    }

    .contentBlock {
      .contentTitle {
        font-size: 13px;
        line-height: 18px;
        color: #8F8F8F;
        margin-bottom: 8px;
      }
      .contentDesc {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #363636;
      }
      .memberList {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(auto-fit, 26px);
        gap: 8px;

        img {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #E3E3E3;
      margin-top: 27px;
      margin-bottom: 21px;
    }
  }

  .footer {
    padding-bottom: 40px;
    .btn {
      font-size: 14px;
      margin: 50px auto 0;
      text-align: center;

      .btnBox{
        margin: 0 auto;
      }
    }
  }
}

.cancelRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 522px;

  .cancelImg {
    width: 260px;
    img {
      width: 100%;
    }
  }

  .cancelText {
    margin-top: 16px;
    font-weight: 400;
    font-size: 15px;
    color: #B4B4B4;
  }
}
