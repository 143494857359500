.info {
  display: flex;
  flex-direction: row;

  width: 100%;
  min-height: 40px;


  &_left {
    min-width: 112px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      // margin-top: 1px;
    }

    span {
      position: relative;
      top: 1px;

      height: 20px;
      font-size: 14px;

      font-weight: 400;
      color: #8f959e;
      line-height: 20px;
    }
  }

  &_right {
    color: #262626;
    font-size: 14px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;

    height: 40px;
    line-height: 20px;

    padding-bottom: 20px;
  }
}


.info2 {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;


  &_top {
    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 32px;
    padding-bottom: 12px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    span {
      position: relative;
      top: 1px;

      height: 20px;
      font-size: 14px;

      font-weight: 400;
      color: #8f959e;
      line-height: 20px;
    }
  }

  &_bottom {
    color: #1dd2c1;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 12px;
    // font-family: PingFangSC-Regular, PingFang SC;

    height: auto;
    line-height: 20px;
    padding-top:2px;    
  }
}
