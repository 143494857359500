@mixin ellipsis($line) {
  @if $line == 1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @if $line > 1 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
}
