@import "./mixin.scss";

.fx-file-card {
  padding-bottom: 6px;
  width: auto;
  height: auto;

  &__img {
    position: relative;
    font-size: 0;
    width: 70px;
    height: 70px;
    margin-right: 6px;
    border-radius: 4px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  &__info {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    color: #fff;
    font-size: 12px;
  }

  &__name {
    @include ellipsis(3);

    // padding-top: 20px;
    padding-top: 14px;
    padding-left: 7px;
    padding-right: 7px;

    // font-size: 9px;

    font-weight: 500;
    color: #ffffff;
    line-height: 13px;

    font-size: 12px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.9);

    // width: 60px;
    height: 50px;
    // overflow: hidden;
    // word-wrap: break-word;

    // font-size: 9px;
    // line-height: 14px;

    word-break: break-all;
  }

  &__size {
    // padding-top: 22px;

    // font-size: 7px;
    font-size: 12px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.7);

    position: relative;
    left: 2px;

    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 9px;

    padding-left: 6px;
    font-size: 7px;
  }
}
