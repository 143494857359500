.fx-avatar-default {
  // width: 42px;
  width: 26px;
  height: 26px;
  // height: 42px;

  img {
    width: 100%;
    border-radius: 50%;
  }
}

.fx-avatar-large {
  width: 59px;
  height: 59px;

  img {
    width: 100%;
    border-radius: 50%;
  }
}