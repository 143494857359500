.memberListRoot {
  margin-bottom: 38px;
  .memberTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #060606;
    margin-bottom: 12px;
  }
  .memberList {
    display: grid;
    grid-template-columns: repeat(auto-fill, 36px);
    grid-gap: 16px 16px;
    .avatarBox {
      width: 36px;
      height: 36px;
      position: relative;

      .avatar {
        border-radius: 50%;
        overflow: hidden;
      }

      .finished {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 16px;
        height: 16px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}