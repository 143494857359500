

.rights{
  margin-bottom: 70px;
  
  &__wrapper{
    text-align: center;

    &>div{
      display: inline-block;
      color: rgba(38, 38, 38, 0.297571);
      font-size: 11px;
    }

    a{
      color: #262626;
    }
  }
}