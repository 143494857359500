

.info{
  padding-top: 22px;
  padding-bottom: 10px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 8px;

    img{
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &-name{
    text-align: center;
    color: #6A6A6A;
    // margin-top: 8px;
    // margin-bottom: 10px;
  }

  &-status{
    text-align: center;
  }

  &-tips{
    font-size: 11px;
    color: #AFAFAF;
    text-align: center;
    // margin-top: 8px;
  }

  &-status{
    font-weight: 500;
    font-size: 20px;

    &--joined{
      color: #1DD2C1;
    }

    &--ongoing{
      color: #1DD2C1;
    }

    &--unapproved{
      color: #FE4D4D;
    }

    &--fail{
      color: #FFAC44;
    }

    &--askForLeave{
      color: #FFAC44;
    }

    &--refused{
      color: #FE4D4D;
    }
  }
  
}