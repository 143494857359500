
.code{
  margin-bottom: 50px;
  margin-top: -64px;

  &__tips{
    font-size: 15px;
    color: #b4b4b4;
    margin-bottom: 30px;
  }

  &__box{
    vertical-align: middle;

    &-wrapper{
      display: flex;
      justify-content: space-between;
    }

    flex: 1;
    display: inline-block;
    font-size: 0;
    width: 40px;
    height: 48px;
    line-height: 48px;
    background: #F4F4F4;
    border-radius: 8px;
    margin-left: 4px;
    margin-right: 4px;

    &:nth-child(1){
      margin-left: 0;
    }

    &:nth-last-child(1){
      margin-right: 0;
    }

    &-txt{
      height: 100%;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #000000;
    }
  }

  &__input{
    position: absolute;
    left: -9999px;

    input{
    }
  }

  &__error{
    font-size: 12px;
    color: #FE4D4D;
    margin-top: 8px;
  }
}