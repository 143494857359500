.summaryCardRoot {
  width: 100%;
  background: #ECFBFA;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  padding-bottom: 14px;
  margin-bottom: 16px;
  .colorBar {
    width: 100%;
    height: 8px;
    background: #BAEDEA;
  }
  .tag {
    width: 40px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 11px;
    color: #FFFFFF;
    background-color: #000000;
    border-radius: 16px;
  }
  .contentBox {
    padding: 0 16px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 10px;
      line-height: 20px;
      color: #8F8F8F;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    line-height: 17px;
    color: #8B8B8B;
    padding: 0 16px;
    margin-top: 6px;
    img {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
}