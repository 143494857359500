

.bg{
  font-size: 14px;
  color: #262626;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;

  // margin-top: 15px;
  // margin-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 12px;
}