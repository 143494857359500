.tips-text {
  font-size: 12px;
  font-weight: 400;
  color: #4B4B4B;
  line-height: 13px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.warning-text {
  font-size: 12px;
  color: #FA1B3E;
  margin-top: 0;
}
.failIcon {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}

.pc-tips-popup-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 404px;
  background: #FFFAF4;
  border: 1px solid #FFFAF4;
  margin: 0 auto;
  border-radius: 8px;
  position: absolute;
  bottom: 8px;
  // left: 25%;
  left: 50%;
  transform: translateX(-50%);
}

.m-tips-popup-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 270px;
  margin: 0 auto 32px;
  border-radius: 8px;
  border: 1px solid #FFFAF4;
  background: #FFFFFF;
  box-shadow: 0 2px 18px 4px rgba(143, 143, 143, 0.2);

  .tips-text {
    color: #363636;
    line-height: 24px;
    margin: 10px;
  }
}
