.btn {
  width: 100%;
  padding: 11px 0px 11px 0px;
  text-align: center;

  user-select: none;
  cursor: pointer;

  background: linear-gradient(45deg, #1dd2c1 0%, #00e09d 100%);
  border-radius: 8px;

  font-size: 20px;

  font-weight: 500;
  color: #ffffff;
  line-height: 28px;

  &:hover {
    opacity: 0.7;
    background: linear-gradient(45deg, #1dd2c1 0%, #00e09d 100%);
  }
}
