.header {
  width: 100%;
  background: #fff;
  color: #060606;
  display: inline-block;

  // 左侧头像
  // .left {
  //   padding-left: 12px;
  //   padding-right: 12px;
  // }

  // 1 顶部：头像、邀请时间、所属事项
  // 右侧邀请人
  &__invite_info {
    float: left;

    font-size: 12px;
    font-weight: 400;
    color: #060606;
    line-height: 17px;

    color: rgba(6, 6, 6, 0.45);

    word-break: break-all;
    max-width: calc(100vw - 100px);

    padding-bottom: 8px;
  }

  // 最右边所属事项
  &__right_include {
    float: right;
    min-width: max-content;

    @media screen and (max-device-width: 776px) {
      float: left;
      padding-left: 48px;
    }

    .text-gray {
      font-size: 12px;
      font-weight: 400;
      color: rgba(6, 6, 6, 0.3);
      line-height: 17px;
    }

    .text-green {
      font-size: 12px;
      font-weight: 400;
      color: #1dd2c1;
      line-height: 17px;
    }
  }
}

.left,
.right {
  float: left;
}
