.win_open {
  max-width: 800px;
  width: 100%;
  max-height: 58px;

  overflow: hidden;

  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  display: block;

  border-radius: 8px;

  margin-bottom: 14px;

  &__logo {
    float: left;

    padding: 17px 9px 17px 34px;

    img {
      width: 24px;
    }
  }

  &__text {
    float: left;

    margin: 18px 0px 0px 0px;
  }

  &__btn {
    float: right;
    margin: 14px 26px 0px 0px;
    user-select: none;

    background: #00d3c1;
    border-radius: 8px;

    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;

    padding: 5px 15px 5px 17px;

    &:hover{
        background: #61dfd3;
    }
  }
}
