.noDataRoot {
  width: 100%;
  height: calc(100vh - 92px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .noDataImg {
    width: 240px;
    height: 155px;
    margin-bottom: 15px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .noDataText {
    font-size: 15px;
    line-height: 21px;
    color: #B4B4B4;
  }
}