

.form{
  // margin-bottom: 80px;

  &--hidden{
    display: none;
  }

  &__input{

    input{
      font-size: 24px !important;
    }
  }
  
}