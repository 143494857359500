.file-list-container {
  width: 100%;
  text-align: left;
  position: relative;

  .file-icon {
    width: 24px;
    height: 30px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .more {
    width: 62px;
    height: 21px;
    background: #f7f7f7;
    color: #8f8f8f;
    font-size: 12px;
    border-radius: 4px;
    text-align: center;
    line-height: 21px;
    margin: 16px auto 14px;
    cursor: pointer;
  }
}
