.line {
  border: 1px solid #ededed;

  border-radius: 24px;
  padding: 10px 14px;
  margin-bottom: 10px;
  margin-right: 10px;

  width: max-content;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &__left {
    img {
      position: relative;
      top: -2px;
      width: 16px;
    }

    margin-right: 18px;
    font-size: 15px;
    font-weight: 400;
    color: #060606;
    line-height: 21px;
  }

  &__center {
    line-height: 27px;

    &_green {
      float: left;
      margin-right: 9px;
      font-size: 14px;
      font-weight: 400;
      color: #1dd2c1;
      line-height: 21px;
    }
  }

  &__right {
    float: left;

    height: 36px;

    padding-top: 2px;

    &_dot {
      margin-left: 9px;
      float: left;
      display: block;
      width: 1px;
      height: 10px;
      background: rgba(6, 6, 6, 0.2);
      margin-top: 6px;
      margin-right: 10px;
    }

    &_remind {
      padding-top: 1px;
      float: left;
      font-size: 14px;
      font-weight: 400;
      color: #060606;
      line-height: 20px;
      opacity: 0.45;
    }
  }

  .calendar_icon_btn {
    padding: 0;
    margin-left: 9px;
  }

  .calendar_icon_btn:hover {
    background: none;
    opacity: 0.7;
  }
}

.icon_top_1 {
  position: relative;
  top: 1px;
}
