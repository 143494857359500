.btn-theme-big-theme {
  width: 33%;
  margin: 0 8px;
  text-align: left;

  button {
    background: linear-gradient(32deg, #00e09d 0%, #1dd2c1 100%);
    border: 0.6px solid #1dd2c1;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    padding: 8px 0;

    min-width: 120px;
    @media screen and (max-device-width: 776px) {
      min-width: 101px;
    }
  }

  button:hover {
    opacity: 0.7;
    // background: rgba(254, 77, 77, 1);
  }
}

.btn-theme-big-theme-sp {
  width: 140px;
  // margin: 0 auto;
  text-align: right;

  button {
    background: linear-gradient(32deg, #00e09d 0%, #1dd2c1 100%);
    border: 0.6px solid #1dd2c1;
    border-radius: 20px;
    color: #fff;
    font-size: 14px;
    padding: 8px 0;

    min-width: 120px;
  }

  button:hover {
    opacity: 0.7;
    // background: rgba(254, 77, 77, 1);
  }
}


.btn-theme-big-red {
  width: 33%;
  margin: 0 8px;

  text-align: right;

  button {
    background: rgba(254, 77, 77, 1);
    border: 0.6px solid rgba(254, 77, 77, 1);
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    padding: 8px 0;

    min-width: 120px;
    @media screen and (max-device-width: 776px) {
      min-width: 101px;
    }
  }

  button:hover {
    background: rgba(254, 77, 77, 0.6);
  }
}

.btn-theme-big-orange {
  // width: 33%;
  width: 18%;
  margin: 0 8px;

  button {
    background: rgba(255, 172, 68, 1);
    border: 0.6px solid rgba(255, 172, 68, 1);
    border-radius: 20px;
    color: #fff;
    font-size: 14px;
    padding: 8px 0;

    min-width: 120px;
    @media screen and (max-device-width: 776px) {
      min-width: 101px;
    }
  }

  button:hover {
    background: rgba(255, 172, 68, 0.7);
  }
}

.btn-theme-big-large {
  width: 300px;
  height: 40px;

  button {
    width: 100%;
    height: 100%;
    background: #1DD2C1;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    text-align: center;
  }

  button:hover {
    opacity: 0.7;
  }
}

button:focus,
button:active:focus,
button.active:focus,
button.focus,
button:active.focus,
button.active.focus {
  outline: none;
  // border-color: transparent;
  box-shadow: none;
}
