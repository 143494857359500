

.wrap{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__pic{
    width: 100px;
    height: 100px;
    margin-bottom: 30px;

    img{
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__desc{
    font-size: 14px;
    color: #b4b4b4;
  }
}