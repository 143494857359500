

.form{

  &__input{
    position: relative;
    width: 295px;
    // margin: 40px auto 0;
    margin: 28px auto 40px;

    &--readonly{

      // input{

        &::after{
          // content: none;
          display: none;
        }
      // }

      .form__input{

        &-clear{
          display: none;
        }
      }
    }

    input{
      box-sizing: content-box;
      width: 100%;
      height: 20px;
      // padding-bottom: 12px;
      border: none;
      font-weight: 500;
      font-size: 20px;
      outline: none;
      background-color: transparent;

      &::-webkit-input-placeholder{

        font-weight: 400;
        font-size: 13px;
      }

    }

    &::after{
      content: '';
      position: absolute;
      width: 295px;
      // width: 100%;
      height: 1px;
      background-color: #E5E5E5;
      transform: scaleY(0.5);
      bottom: 0;
      left: 0;
    }

    &-clear{
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
    }

    &-tips{
      position: absolute;
      bottom: -25px;
      font-size: 12px;
      color: #FE4D4D;
    }
  }
}