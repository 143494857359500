/* eslint-disable no-debugger, no-console */
/* stylelint-disable */
.invite-task-child {
  padding: 0px 0px 29px 0;

  &__title {
    display: inline-block;
    width: 100%;

    &_left {
      float: left;

      opacity: 0.87;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #060606;
      line-height: 28px;
    }

    &_right {
      float: right;
      padding-top: 6px;

      font-size: 13px;
      font-weight: 400;
      color: #4b5f8b;
      line-height: 18px;

      button {
        padding: 0px;
      }

      button:hover {
        padding: 0px;
        background: none;
      }

      img {
        width: 18px;
        margin-right: 6px;
      }
    }
  }
}

// .sortItem {
//   user-select: none;

//   padding: 10px 6px 9px 6px;
//   margin: 0 0 7px 0;
//   border-radius: 5px;
// }
