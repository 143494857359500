

.info{
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 20px;

  &__header{
    display: flex;
    align-items: center;
  }

  &__left{
    width: 75px;
  }

  &__right{
    flex: 1;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__avatar{
    position: relative;
    // width: 58px;
    // height: 58px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      display: block;
      width: 58%;
      height: 58%;
    }

    &-tool{
      position: absolute;
      right: -15px;
      top: -4px;
      // background-color: #9899A2;
      height: 20px;
      line-height: 20px;
      padding: 0 6px;
      color: #FEFEFE;
      font-weight: 500;
      font-size: 11px;
      border-radius: 12px;
    }
  }

  &__name{
    font-weight: 500;
    font-size: 18px;
    color: #252525;
  }

  &__creator{
    font-size: 14px;
    color: #C0C0C0;
    // width: calc(100% - 30px - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
}