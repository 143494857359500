.assistProcessRoot {
  .title {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: #262626;
    margin-bottom: 12px;
  }
  .listBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .listItem {
      width: 180px;
      height: 86px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #F8F8F8;
      border-radius: 8px;
      padding: 12px 16px 12px 20px;
      margin-bottom: 10px;
      .left {
        .num {
          font-weight: 600;
          font-size: 32px;
          line-height: 37px;
          color: #262626;
        }
        .text {
          font-size: 15px;
          line-height: 21px;
          color: #8F8F8F;
        }
      }
      .right {
        width: 56px;
        height: 50px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .mobileClass {
    .listItem {
      width: 49%
    }
  }
}