.pos {
  position: fixed;
  bottom: 105px;
  width: 252px;

  left: calc(50vw - 126px);
}

.btn_list {
  display: flex;
  justify-content: space-between;
}

.btn_default {
  user-select: none;

  max-width: 118px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #b4b4b4;

  padding: 9px 0px 9px 0px;
  text-align: center;

  font-size: 15px;
  // font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #92929d;
  line-height: 21px;

  background: #fff;

  flex: 1;

  &:hover {
    background: #f3f3f3;
  }
}

.btn_attend {
  user-select: none;

  max-width: 118px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #5d5eee;

  background: #5d5eee;

  padding: 9px 0px 9px 0px;
  text-align: center;

  font-size: 15px;
  // font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 21px;

  flex: 1;

  &:hover {
    background: #9396ed;
  }
}
