.fx-layout-anser {
  width: 100%;
  text-align: center;
  .header {
    width: 59px;
    // width: 120px;
    // height: 59px;
    display: block;
    margin: 0 auto;
  }

  .content {
    display: block;
    height: auto;
    width: 100%;

    .ans-title {
      padding-top: 24px;
      padding-bottom: 20px;

      font-size: 26px;
      font-weight: bolder;

      .orange {
        color: rgba(255, 172, 68, 1);
      }

      .red {
        color: rgba(254, 77, 77, 1);
      }

      .theme {
        color: rgba(29, 210, 193, 1);
      }

      .black {
        color: #2f2f31;
      }
    }
    .ans-result {
      font-weight: bolder;
      padding-bottom: 57px;
      font-size: 15px;
      opacity: 0.87;
    }
    .ans-from {
      font-size: 15px;
      opacity: 0.87;
      padding-bottom: 2px;
    }
    .ans-task-name {
      font-weight: bolder;
      font-size: 15px;
      opacity: 0.87;
      padding-bottom: 2px;
    }
  }

  .ad {
    .info {
      display: block;
      padding-bottom: 28px;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      opacity: 0.45;
      color: #060606;
    }

    .icon {
      width: 80px;
      height: 80px;
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 0px 1px 10px 0px rgba(6, 6, 6, 0.2);
      border-radius: 21px;
      img {
        position: relative;
      }
    }

    .btn-download {
      margin: 40px auto 0px;
      width: 204px;
    }

    button {
      width: 190px;
    }
  }
}
