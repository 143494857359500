.subTaskItem {
  display: flex;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 11px 11px 16px;

  .itemIcon {
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-right: 5px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .itemRight {
    width: calc(100% - 21px);
    .itemTitle {
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 2px;
    }
  }
}