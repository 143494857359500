.loginTypeRoot {
  padding: 12px 15px;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .topTitle {
      min-width: 56px;
      font-weight: 400;
      font-size: 14px;
      color: #4E5969;
      margin: 0 12px;
    }

    img {
      width: 117px;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;

    .contentItem {
      width: 85px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .itemIcon {
        width: 32px;
        height: 32px;
        margin-bottom: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .contentTitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #4E5969;
      }
    }
  }
}