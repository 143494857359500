.bodylayer {
  width: 100vw;
  height: 100vh;
  // min-height: 100vh;
  height: 100vh;

  overflow-y: scroll;
  overflow-x: hidden;

  background: #f8f8f8;

  .inner {
    margin: 0 auto;

    max-width: 800px;
    min-height: 865px;

    display: block;

    background: #ebeaf9;
  }
}

.pc {
  &_title {
    color: #262626;
    font-size: 28px;
    // font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #262626;
    line-height: 40px;

    text-align: center;

    padding-top: 95px;

    &_blue {
      color: #5d5eee;
    }
  }

  &_icon {
    width: 64px;
    height: 64px;
    background: #df78b0;
    border: 2px solid rgba(233, 233, 233, 0.7);
    display: block;
    border-radius: 50%;

    position: relative;

    margin: 20px auto 0px;

    &_bandage {
      width: 50px;
      // width: 45px;
      // width: auto;
      height: 20px;
      background: #5d5eee;
      border-radius: 10px;

      padding: 2px 6px;

      color: #fefefe;
      font-size: 11px;
      line-height: 16px;

      position: absolute;

      text-align: center;

      top: -2px;
      right: -16px;
    }
  }

  &_space_name {
    text-align: center;
    margin-top: 12px;

    font-size: 18px;
    // font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #363636;
    line-height: 25px;

    text-align: center;
  }

  &_space_creator {
    font-size: 12px;
    // font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #6a6a6a;
    line-height: 17px;

    text-align: center;

    margin-top: 4px;
  }

  &_space_desc {
    width: 408px;
    margin: 29px auto 0px; // margin: 29px auto 14px;

    &_title {
      font-size: 13px;
      // font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8f8f8f;
      line-height: 18px;

      margin-bottom: 12px;
    }

    &_content {
      font-size: 13px;
      // font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 18px;

      margin-bottom: 10px;
    }
  }
}

.more {
  width: 62px;
  height: 21px;
  background: #f7f7f7;
  border-radius: 4px;

  margin: 16px auto 0px;
  padding: 2px 0px;

  text-align: center;

  font-size: 12px;
  // font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
  line-height: 17px;

  cursor: pointer;
  user-select: none;
}
