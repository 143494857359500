.wx_back {
  background: #f8f8f8;
  width: 100vw;
  height: max-content;
  min-height: 100vh;

  padding-bottom: 120px;
  display: block;

  &__inner {
    padding-top: 120px;
    text-align: center;
    width: 100%;

    img {
      width: 190px;
    }
  }

  &__text {
    padding-top: 42px;
    text-align: center;
    width: 100%;

    font-size: 13px;

    font-weight: 400;
    color: #3b3a3f;
    line-height: 18px;
  }

  &__block_btn {
    width: 100%;
    margin-top: 55px;
    text-align: center;
    color: #ffffff;

    div {
      min-height: 50px;
      width: 70%;
      background: #1dd2c1;
      border-radius: 8px;
      border: none;
      // padding: 15px 0 14px 0;
      font-size: 15px;
      margin:0 auto;

      color: #ffffff;

      wx-open-launch-weapp {
        padding: 13px 0px 14px 0px;
        place-items: center;
        width: 100%;
        height: 100%;
        display: block;
        background: #1dd2c1;
        color: #fff;
        border-radius: 8px;
        border: none;
        color: #fff;
      }
    }

    // div:hover {
    //   background: #61dfd3;
    // }
  }
}
