.ExpiredPageRoot {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    width: 66px;
    height: 66px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    margin-top: 27px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #262626;
  }
}