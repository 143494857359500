
.wrapper{
  flex: 1;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
}

.title{
  color: #060606;

  h3{

    font-weight: 600;
    font-size: 24px;
  }

  h5{
    font-size: 15px;
    color: #b4b4b4;
    margin-top: 4px;
    font-weight: 400;
  }
}

.form{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__code{

    &-refresh{
      font-size: 14px;
      color: #1DD2C1;
      text-align: center;
      margin-top: 30px;
    }

    &-timer{
      font-size: 14px;
      color: #CCCCCC;
      text-align: center;
      margin-top: 36px;
    }
  }
}

.toast{
  padding: 17px 13px;

  &-tick{
    height: 27px;
    width: 38px;
    margin: 0 auto 17px;
  }

  &-txt{
    font-size: 16px;
  }

}