/* eslint-disable no-debugger, no-console */
/* stylelint-disable */

.btn-primary {
  &-white > button {
    background: #fff;
    border: 0.6px solid rgba(201, 201, 201, 1);
    border-radius: 16px;
    color: rgba(6, 6, 6, 0.87);
    font-size: 14px;
    padding: 4px 0;
    width: 96px;
  }
  &-white > button:hover {
    // border: 0.6px solid rgba(0, 0, 0, 0.01);
    background: rgba(0, 0, 0, 0.05);
  }
  &-white {
    min-width: 96px;
    margin: 0 8px;
  }

  &-default > button {
    background: rgba(29, 210, 193, 1);
    border: 0.6px solid rgba(29, 210, 193, 1);
    border-radius: 16px;
    color: #fff;
    font-size: 14px;
    padding: 4px 0;
    width: 96px;
  }
  &-default > button:hover {
    border: 0.6px solid rgba(29, 210, 193, 0.6);
    background: rgba(29, 210, 193, 0.6);
  }
  &-default {
    min-width: 96px;
    margin: 0 8px;
  }

  &-blue > button {
    background: rgba(93, 123, 255, 1);
    border: 0.6px solid rgba(93, 123, 255, 1);
    border-radius: 16px;
    color: #fff;
    font-size: 14px;
    padding: 4px 0;
    width: 96px;
  }
  &-blue > button:hover {
    opacity: 0.7;
  }
  &-blue {
    min-width: 96px;
    margin: 0 8px;
  }

  &-red > button {
    background: rgba(254, 77, 77, 1);
    border: 0.6px solid rgba(254, 77, 77, 1);
    border-radius: 16px;
    color: #fff;
    font-size: 14px;
    padding: 4px 0;
    width: 96px;
  }
  &-red > button:hover {
    opacity: 0.7;
  }
  &-red {
    min-width: 96px;
    margin: 0 8px;
  }

  &-linear-green > button {
    background: linear-gradient(32deg, #00e09d 0%, #1dd2c1 100%);
    // border: 0.6px solid rgba(93, 123, 255, 1)
    border: 0.6px solid linear-gradient(32deg, #00e09d 0%, #1dd2c1 100%);
    border-radius: 16px;
    color: #fff;
    font-size: 14px;
    padding: 4px 14px;

    img {
      margin-top: -2px;
      margin-right: 4px;
    }
  }
  &-linear-green > button:hover {
    opacity: 0.7;
    // background: rgba(29, 210, 193, 0.6);
  }
  &-linear-green {
    min-width: 96px;
    margin: 0 8px;
  }
}
