body{font-family: Arial,Helvetica,sans-serif;  }
/*html{background:none repeat scroll 0 0#FFFFFF;color:#000000; font-size:13.33333333vw;}*/
html{background:none repeat scroll 0 0#FFFFFF;color:#000000;}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td{margin:0;padding:0;}
table{border-collapse:collapse;border-spacing:0}
fieldset,img{border:0 none}
address,caption,cite,code,dfn,em,strong,th,var{font-style:normal;font-weight:normal}
li{list-style:none outside none}
caption,th{text-align:left}
h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal}
q:before,q:after{content:""}
abbr,acronym{border:0 none;font-variant:normal}
sup{vertical-align:text-top}sub{vertical-align:text-bottom}
input,textarea,select{font-family:inherit;font-size:inherit;font-weight:inherit}
input,textarea,select{}input::-moz-focus-inner{border:0;padding:0}
legend{color:#000000}
select,input,button,textarea{font:99%arial,helvetica,clean,sans-serif}
table{font-size:inherit}
pre,code,kbd,samp,tt{font-family:monospace;line-height:100%}
a{text-decoration:none;color:#000000}
a:hover,a:active,a:focus{text-decoration:underline;color:#000000}
strong{font-weight:bold}
input[type="submit"]{cursor:pointer}
button{cursor:pointer}
