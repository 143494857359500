
.login{
  height: 100vh;
  padding-top: 40px;
  display: flex;
  flex-direction: column;

  &__banner{
    width: 218.9px;
    height: 139.68px;
    margin: 0 auto 20px;

    img{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.btn {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #1DD2C1;
  color: #fff;
  border-radius: 10px;
}