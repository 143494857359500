.fx-float-left {
  float: left;
}

.time-remind {
  min-height: 60px;
  display: inline-block;
  // padding: 20px 0;
  padding: 19px 0 20px 0px;

  .icon-time {
    // padding-top: 16px;
    margin-right: 16px;

    img {
      width: 16px;
    }
  }

  .box-text {
    width: calc(100vw - 110px);

    padding-top: 2px;

    opacity: 0.87;
    font-size: 14px;

    font-weight: 400;
    color: #060606;
    line-height: 20px;
  }
}
