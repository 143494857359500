.showCardBoxRoot {
  width: 100%;
  background: #FAFAFA;
  border-radius: 6px;
  padding: 12px 13px 10px 16px;
  overflow: hidden;
  position: relative;
  margin-bottom: 21px;
  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #060606;
    margin-bottom: 10px;
  }
  .allTextBox {
    width: 100%;
    height: 120px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 11px;
    line-height: 15px;
    color: #979797;
    background: linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, #F8F8F8 60.42%);
    padding-right: 18px;
    z-index: 1;
    padding-bottom: 24px;
  }
}