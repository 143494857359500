.pageRoot {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background: #f3f3f3;

  .mainRoot {
    max-width: 800px;
    margin: 0 auto;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 40px;
    padding-bottom: calc(40px + constant(safe-area-inset-bottom)); /*兼容 IOS<11.2*/
    padding-bottom: calc(40px + env(safe-area-inset-bottom)); /*兼容 IOS>11.2*/
  }

  .contentRoot {
    padding: 0 15% 20px;
    background-color: #fff;
    border-radius: 8px;
  }

  .mobileClass {
    margin-top: 20px;
    padding: 0 20px;
  }
}