.fileListRoot {
  display: grid;
  grid-template-columns: repeat(4, 120px);
  grid-template-rows: repeat(2, 120px);
  grid-gap: 6px;
}

.pb30 {
  padding-bottom: 30px;
}

.fileListItem {
  position: relative;
  .fileListInfo {
    width: 80%;
    height: 100%;
    position: absolute;
    top: 17px;
    left: 10px;
    color: #fff;

    .infoName {
      height: 45%;
    }

    .infoSize {
      margin-top: 20px;
      font-size: 10px;
    }
  }
}