.inviteSpaceRoot {
  width: 800px;
  background: #FFFFFF;
  border-radius: 8px;
  position: relative;

  .bgHeader {
    width: 100%;
    height: 249px;
    background-size: 100%;
    position: absolute;
    top: 0;
  }

  .content {
    width: 408px;
    padding: 66px 0 84px;
    margin: 0 auto;
    text-align: center;
    position: relative;

    .title {
      color: #262626;
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 20px;
    }

    .invite-text {
      color: rgba(93, 94, 238, 1);
    }

    .avatar-info {
      width: 64px;
      height: 64px;
      margin: 0 auto;
      position: relative;

      .avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid rgba(233, 233, 233, 0.7);
      }

      .version {
        width: 45px;
        height: 20px;
        background: #9899a2;
        border-radius: 10px;
        color: #fefefe;
        font-size: 11px;
        text-align: center;
        line-height: 20px;
        position: absolute;
        top: 0;
        right: -10px;

        &.pro {
          background: #5d5eee;
        }
      }
    }

    .team-name {
      color: #363636;
      font-size: 18px;
      line-height: 25px;
      margin: 12px 0 4px;
    }

    .creator {
      color: #6a6a6a;
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 29px;
    }

    .sub-title {
      color: #8f8f8f;
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 12px;
      text-align: left;
    }

    .desc {
      color: #262626;
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 10px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .desc,
    .file-list,
    .member-list {
      text-align: left;
    }

    .member-list {
      .avatar {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 10px;
        margin-bottom: 10px;

        &:nth-child(10n + 0) {
          margin-right: 0;
        }
      }
    }
  }

  .footer {
    padding-bottom: 105px;
    .btn {
      font-size: 14px;
      margin: 50px auto;
      text-align: center;

      .refuse,
      .accept {
        display: inline-block;
        width: 118px;
        height: 40px;
        border-radius: 8px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
      }

      .refuse {
        border: 1px solid #b4b4b4;
        color: #92929d;
        margin-right: 16px;
      }

      .accept {
        background: #5d5eee;
        color: #fff;
      }

      &__box{
        margin: 0 auto;
      }
    }

    .wait,
    .jump-space-btn {
      width: 209px;
      height: 40px;
      line-height: 40px;
      background: #5d5eee;
      border-radius: 8px;
      color: #fff;
      font-size: 15px;
      text-align: center;
      margin: 0 auto;
      cursor: pointer;
    }

    .text {
      color: #92929d;
      font-size: 14px;
      margin-bottom: 10px;
      text-align: center;

      .time {
        color: #b4b4b4;
        font-size: 11px;
      }
    }
  }
}
.tips {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: bolder;
}
