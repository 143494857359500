
.footer{
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);

  &__wrapper{
    height: 50px;
    width: 352px;
    border-radius: 8px;
    line-height: 50px;
    background: #1DD2C1;
  }

  &__txt{
    font-weight: 500;
    font-size: 15px;
    color: #fff;
    text-align: center;
  }

}