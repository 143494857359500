

.btn{
  position: fixed;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  bottom: 32px;
  width: 352px;
  height: 50px;
  line-height: 50px;
  background: #1DD2C1;
  border-radius: 8px;

}