#launch-btn {
  width: 100%;
  height: 100%;
}

.h5-pc-invite {
  background: #f8f8f8;

  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;

  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/

  .inner {
    display: block;

    width: 100%;
    overflow: hidden;

    max-width: 750px;
    margin: 0 auto;

    // padding-top: 35px;
    padding-top: 70px;

    padding-bottom: 120px;

    // 头像栏
    .avatar_title {
      padding-left: 11px;

      margin-top: 16px;
      margin-bottom: 4px;

      height: 42px;

      .avatar-img {
        width: 26px;
        height: 26px;
        margin: 0 11px;

        img {
          width: 26px;
          height: 26px;
          display: block;

          border-radius: 50%;
          border: 2px solid #fff;
        }
      }

      .nick-name {
        font-size: 13px;
        font-weight: 400;
        color: #060606;
        line-height: 18px;
        opacity: 0.87;

        margin-right: 6px;

        max-width: 44%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        padding-top: 4px;
      }

      .desc {
        font-size: 12px;

        font-weight: 400;
        color: #060606;
        line-height: 17px;
        opacity: 0.3;

        padding-top: 5px;
      }
    }

    // 白色内容板
    .white-content {
      overflow: hidden;
      background: #fff;

      margin: 0px 12px;
      // padding: 14px 16px;
      padding: 14px 16px 0 16px;

      border-radius: 8px;

      margin-bottom: 10px;

      // 面包屑
      .parent_bread {
        display: inline-block;

        font-size: 12px;

        font-weight: 400;
        color: #060606;
        line-height: 17px;
        opacity: 0.3;

        margin-bottom: 8px;

        .sub-title {
          margin-right: 6px;
        }
      }

      .title {
        font-size: 18px;
        font-weight: 500;
        color: #252525;
        line-height: 25px;

        margin-bottom: 16px;
      }

      .detail {
        font-size: 14px;

        font-weight: 400;
        color: #060606;
        line-height: 22px;

        opacity: 0.87;

        margin-bottom: 10px;
      }

      .files {
        margin-bottom: 12px;
        height: auto;
        display: inline-block;

        .file {
          float: left;
        }
      }

      .divider-line {
        width: 100%;
        height: 1px;
        background: #ededed;
        display: block;
      }

      .time-range {
        min-height: 74px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon-time {
          margin-right: 16px;

          img {
            width: 16px;
            height: 16px;
          }
        }

        .box-start {
          .line01 {
            font-size: 14px;

            font-weight: 400;
            color: #262626;
            line-height: 20px;
          }

          .line02 {
            font-size: 14px;

            font-weight: bold;
            color: #262626;
            line-height: 26px;
          }
        }

        .box-inner-time {
          // margin-right: 25px;
          margin: 0 12px;
          // padding: 4px 12px;
          padding: 0 12px;
          border-radius: 10px;
          background: #f8f8f8;

          margin-top: 15px;
          max-height: 22px;

          span {
            font-size: 12px;

            font-weight: 300;
            color: #060606;
            line-height: 12px;

            opacity: 0.45;
          }
        }
      }

      .time-remind {
        min-height: 45px;
        display: flex;
        align-items: center;


        .icon-time {
          // padding-top: 16px;
          margin-right: 16px;

          img {
            width: 16px;
          }
        }

        .box-text {
          width: calc(100vw - 110px);

          // padding-top: 2px;

          opacity: 0.87;
          font-size: 14px;

          font-weight: 400;
          color: #060606;
          // line-height: 20px;

          line-height: 24px;

        }
      }

      .child-process {
        .title {
          font-size: 15px;

          font-weight: 500;
          color: #060606;
          line-height: 21px;
          margin-bottom: 16px;
        }

        .title-line {
          min-height: 60px;
          font-size: 14px;

          font-weight: 400;
          color: #060606;
          line-height: 20px;

          opacity: 0.87;

          padding: 20px 0px;

          .time-spot {
            padding-top: 9px;

            opacity: 0.45;

            font-size: 13px;

            font-weight: 400;
            color: #060606;
            line-height: 18px;
          }
        }
      }
    }

    .footer-btn-task {
      position: fixed;
      bottom: 42px;

      // max-width: ;
      padding: 14px 16px;
      width: 100%;

      .center {
        // width: 351px;
        width: 327px;
        margin: 0 auto;

        .left-accept-btn {
          margin-right: 8px;

          button {
            width: 202px;
            height: 48px;
            background: linear-gradient(32deg, #00e09d 0%, #1dd2c1 100%);
            border-radius: 8px;
            text-align: center;
            color: #fff;
          }

          wx-open-launch-weapp {
            width: 202px;
            height: 48px;
            display: block;
            padding-top: 11px;
            background: linear-gradient(32deg, #00e09d 0%, #1dd2c1 100%);
            border-radius: 8px;
            text-align: center;
            color: #fff;
            margin-left: auto;
            margin-right: auto;
          }

          &:hover {
            background: linear-gradient(32deg, #51e6c1 0%, #5fe0d2 100%);
            border-radius: 8px;
          }
        }

        .left-accept-meeting-btn {
          margin-right: 8px;

          button {
            width: 158px;
            height: 48px;
            background: linear-gradient(32deg, #00e09d 0%, #1dd2c1 100%);
            border-radius: 8px;
            text-align: center;
            color: #fff;
          }

          wx-open-launch-weapp {
            width: 158px;
            height: 48px;
            display: block;
            padding-top: 11px;
            background: linear-gradient(32deg, #00e09d 0%, #1dd2c1 100%);
            border-radius: 8px;
            text-align: center;
            color: #fff;
            margin-left: auto;
            margin-right: auto;
          }

          button:hover {
            background: linear-gradient(32deg, #51e6c1 0%, #5fe0d2 100%);
            border-radius: 8px;
          }
        }

        .left-vacate-btn {
          button {
            width: 76px;
            height: 48px;
            background: rgb(255, 172, 68);
            border-radius: 8px;
            text-align: center;
            color: #fff;
            margin-right: 8px;
          }

          wx-open-launch-weapp {
            width: 76px;
            height: 48px;
            display: block;
            padding-top: 11px;
            background: rgb(255, 172, 68);
            border-radius: 8px;
            text-align: center;
            color: #fff;
            margin-right: 8px;
          }

          button:hover {
            background: rgba(255, 172, 68, 0.7);
            border-radius: 8px;
          }
        }

        .right-refuse-btn {
          button {
            width: 117px;
            height: 48px;
            background: #fe4d4d;
            border-radius: 8px;
            text-align: center;
            color: #fff;
          }

          wx-open-launch-weapp {
            width: 117px;
            height: 48px;
            display: block;
            padding-top: 11px;
            background: #fe4d4d;
            border-radius: 8px;
            text-align: center;
            color: #fff;
          }

          button:hover {
            background: #f07e82;
            border-radius: 8px;
          }
        }

        .right-refuse-meeting-btn {
          button {
            width: 76px;
            height: 48px;
            background: #fe4d4d;
            border-radius: 8px;
            text-align: center;
            color: #fff;
          }

          wx-open-launch-weapp {
            width: 76px;
            height: 48px;
            display: block;
            padding-top: 11px;
            background: #fe4d4d;
            border-radius: 8px;
            text-align: center;
            color: #fff;
          }

          button:hover {
            background: #f07e82;
            border-radius: 8px;
          }
        }
      }
    }
  }
}

.footer-btn-task {
  position: fixed;
  bottom: 42px;
  min-width: 45%;
}

.footer-btn{
  width: 352px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  text-align: center;
  background: #1DD2C1;
  border-radius: 8px;
}

.fx-float-left {
  float: left;
}

.project-footer {
  display: flex;
  justify-content: center;
  // gap: 20px;

  .btn {
    width: 120px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid transparent;
    display: grid;
    place-items: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
  }

  .cancel-btn {
    color: rgba(29, 210, 193, 1);
    border-color: rgba(29, 210, 193, 1);
  }

  .confirm-btn {
    max-width: 350px;
    width: 100%;
    height: 100%;
    background: #1DD2C1;
    color: whitesmoke;
    margin-right: 20px;
  }
}

.m-project-footer {
  flex-direction: column;
  align-items: center;
  .btn {
    width: 300px;
    height: 48px;
  }
  .cancel-btn {
    color: #626262;
    border-color: transparent;
  }
  .confirm-btn {
    margin-right: 0;
  }
}

.btn_fixed {
  position: fixed;
  width: calc(100vw - 20px);
  margin: 0px 10px;
  bottom: 40px;
}

.waitBtn {
  width: 209px;
  height: 40px;
  line-height: 40px;
  background: #5d5eee;
  border-radius: 8px;
  color: #fff;
  font-size: 15px;
  text-align: center;
  margin: 0 auto;
  user-select: none;
}
