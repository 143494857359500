.body {
  background: #f3f3f3;
  overflow-y: scroll;
  overflow-x: hidden;
}

.html5-auto {
  max-width: 590px;
  
  width: 100%;
  min-height: 100vh;

  margin: 0 auto;

  // 小于750的尺寸
  padding: 0px 0px 40px 0px;

  // 大于50的尺寸
  @media screen and (min-width: 750px) {
    padding: 40px 0px;
    max-width: 810px;
  }
}
