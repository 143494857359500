.phone_number_login {
  display: flex;
  flex-direction: column;
}

.login_header {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: #262626;
    font-weight: bold;
    font-size: 23px;
  }

  span {
    color: rgba(6, 6, 6, 0.3);
    font-size: 14px;
    margin-top: 8px;
  }
}

.form_wrap {
  padding: 60px 22px 0 22px;

  &__custom_input {
    border-radius: 6px;
    height: 44px;
  }

  .passwordInput {
    :global {
      .ant-input-suffix {
        display: none;
      }
    }
  }

  button {
    background-color: #1dd2c1;
    border-radius: 22px;
    height: 44px;
    color: #fff;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
  }

  button[disabled] {
    background-color: rgba(29, 210, 193, 0.2);
    cursor: auto;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.footer_wrap {
  display: flex;
  color: #262626;
  font-size: 12px;
  justify-content: center;
  margin-top: 20px;

  a {
    color: #1fd3c2;
    cursor: pointer;
  }
}
