
/** 大的 **/
.full-screen-mask {
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);

  .footer-modal-mobile {
    // padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
    // padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
    // padding-bottom: 340px;
    height: 212px;
    border-radius: 12px 12px 0px 0px;
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: 0px;
    left: 0px;

    .line-a {
      padding: 30px 40px 25px 40px;
      display: block;
      width: 100%;
      height: 109px;
      border-bottom: 1px solid #ededed;
      .pic {
        width: 54px;
        height: 54px;
        display: block;
        float: left;
        margin-right: 16px;
        box-shadow: 0 0 4px #DAE0D8;
        border-radius: 12px;

        img {
          width: 54px;
          float: left;
        }
      }

      .word {
        padding-top: 5px;
        max-width: 200px;
        float: left;

        .title {
          font-size: 18px;
          font-weight: 500;
          color: #171718;
          line-height: 25px;
        }

        .desc {
          font-size: 14px;
          font-weight: 300;
          color: #6a6a6a;
          line-height: 20px;
        }
      }
    }

    .line-b {
      width: 295px;
      margin: 0 auto;
      padding-top: 30px;
      font-size: 16px;
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 15px;
      .no {
        width: 140px;
        height: 44px;
        background: #fefffe;
        border-radius: 8px;
        border: 1px solid #b4b4b4;
      }
      .ok {
        width: 140px;
        height: 44px;
        background: #1dd2c1;
        color: #fff;
        border-radius: 8px;
        border: 1px solid #1dd2c1;
      }

      wx-open-launch-weapp {

        width: 140px;
        height: 44px;
        background: #1dd2c1;
        color: #fff;
        border-radius: 8px;
        border: 1px solid #1dd2c1;
        display: grid;
        place-items: center;
      }
    }
  }
}

/** 小的 **/
.full-screen-no-mask {
  background-repeat: no-repeat;
  background-size: 100% auto;

  display: block;

  .footer-modal-mobile {
    height: 70px;
    // border-radius: 12px 12px 0px 0px;
    width: 100%;
    background: #fff;
    position: fixed;
    // bottom: 0px;
    top: 0px;
    left: 0px;
    z-index: 999;
    .line-a {
      height: 100%;
      align-items: center;
      padding: 0rem 0.64rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .pic {
        width: 34px;
        height: 34px;
        display: block;
        float: left;
        margin-right: 12px;
        // box-shadow: 0 0 4px #6f6f6f;
        border-radius: 18px;
        img {
          width: 34px;
          float: left;
        }
      }

      .word {
        padding-top: 4px;
        // max-width: 200px;
        float: left;
        .title {
          font-size: 14px;
          font-weight: 500;
          color: #171718;
          line-height: 25px;
        }

        .desc {
          font-size: 14px;
          font-weight: 300;
          color: #6a6a6a;
          line-height: 20px;
        }
      }

      .no {
        width: 140px;
        height: 44px;
        background: #fefffe;
        border-radius: 8px;
        border: 1px solid #b4b4b4;
        margin-right: 15px;
      }

      .ok {
        float: right;
        width: 68px;
        height: 28px;
        background: #1dd2c1;
        color: #fff;
        font-size: 14px;
        margin-top: 3px;
        border-radius: 8px;
        border: 1px solid #1dd2c1;
      }

      wx-open-launch-weapp {
       // float: right;
       // display: block;
        display: grid;
        place-items: center;
        width: 68px;
        height: 28px;
        background: #1dd2c1;
        color: #fff;
        margin-top: 3px;
        border-radius: 8px;
        border: 1px solid #1dd2c1;
      }
    }
  }
}



.open-box{
  width: 140px;
  height: 44px;
  font-size: 16px;
}

.small-open-box{
  width: 68px;
  height: 28px;
  font-size: 14px;
}

