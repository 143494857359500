.spaceInviteH5Root {
  background: #f8f8f8;

  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;

  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/

  position: relative;

  .bgHeader {
    width: 100%;
    height: 249px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
  }

  .headerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 113px;

    .title {
      font-size: 17px;
      font-weight: 500;
      color: #363636;
      line-height: 24px;
      }

    .avatar-info {
      width: 72px;
      height: 72px;
      margin: 40px auto 15px;
      position: relative;

      .avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid rgba(233, 233, 233, 0.7);
      }

      .version {
        width: 45px;
        height: 20px;
        background: #9899a2;
        border-radius: 10px;
        color: #fefefe;
        font-size: 11px;
        text-align: center;
        line-height: 20px;
        position: absolute;
        top: 0;
        right: -10px;

        &.pro {
          background: blue;
        }
      }
    }
    .team-name {
      font-size: 20px;
      font-weight: 500;
      color: #363636;
      line-height: 28px;
      margin-bottom: 5px;
    }
    .creator {
      font-size: 12px;
      font-weight: 400;
      color: #8F8F8F;
      line-height: 17px;
    }
  }

  .content {
    width: 351px;
    background: #FFFFFF;
    border-radius: 7px;
    margin: 16px auto;
    padding: 20px;

    .spaceMemberBox {
      .memberTitle {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .memberText {
          font-size: 14px;
          font-weight: 500;
          color: #363636;
          margin-right: 8px;
        }
        .memberTips {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          color: #FFAE20;
        }
        .icon {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }
      .memberList {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, 36px);
        grid-gap: 17px 17px;
        .avatar {
          width: 36px;
          height: 36px;
          border: 1px solid #DEDEDE;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
  }
}

.tips {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: bolder;
}
