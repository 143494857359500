.infoRowRoot {
  .infoItem {
    display: flex;
    align-items: baseline;
    margin-bottom: 16px;
    .icon {
      width: 16px;
      height: 16px;
      img {
        width: 100%;
        height: 100%;
      }
      margin-right: 16px;
    }
    .text {
      font-size: 14px;
      line-height: 20px;
      color: #363636;
    }
  }
}