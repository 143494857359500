

.info{
  padding-left: 12px;

  &__title{
    font-weight: 500;
    font-size: 18px;
    color: #252525;
    margin-bottom: 6px;
  }

  &__desc{
    font-size: 14px;
    color: #C0C0C0;
  }
}