

.takers{
  padding-left: 14px;

  &__wrap{
    display: grid;
    grid-template-columns: repeat(6, 16.66%);
  }

  &-item{
    display: inline-block;
    font-size: 0;
    width: 40px;
    // margin-left: 10px;
    // margin-right: 9px;
    margin-bottom: 10px;
  }

  &-avatar{
    position: relative;
    width: 36px;
    height: 36px;

    &-avatar{
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
    }

    &-icon{
      position: absolute;
      display: block;
      width: 14px;
      height: 14px;
      bottom: -2px;
      right: -2px;

      &--askForLeave{
      }
      &--refused{
      }
    }
  }

  &-name{
    font-size: 13px;
    color: #000;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }

  &-status{
    font-weight: 300;
    font-size: 12px;
    color: #262626;
  }

  &-sum{
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
  }
}