.details {
  width: 528px;
  margin-bottom: 14px;
  display: inline-block;

  &_desc {
    color: rgba(6, 6, 6, 0.87);
    font-size: 14px;
    word-break: break-all;
  }

  &_files {
    margin-top: 16px;

    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
}
