
.button{
  width: 295px;
  height: 45px;
  line-height: 45px;
  background: linear-gradient(44.77deg, #00E09D 25.36%, #1DD2C1 74.98%);
  border-radius: 8px;
  margin: 0 auto;
  text-align: center;

  &--disabled{
    opacity: .3;
  }

  &__wrapper{
  }

  &-txt{
    color: #fff;
    font-size: 16px;
  }

}