.targetInviteH5Root {
  background: #f8f8f8;

  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;

  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/

  position: relative;

  .contentRoot {
    margin-top: 60px;
    height: calc(100% - 60px);
    position: relative;
    background: linear-gradient(180deg, #D4EFFF 0%, rgba(255, 255, 255, 0.68) 25%);
  }

  .mt0 {
    margin-top: 0;
  }


  .bgIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: 124px;
    height: 127px;

    img {
      width: 100%;
      height: 100%;
    }
  }


  .content {
    width: 100%;
    padding: 66px 0 84px;
    margin: 0 auto;

    .title {
      color: #262626;
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 20px;
      text-align: center;
    }

    .invite-text {
      color: #1DD2C1;
    }

    .wait-text {
      color: #FFAC44;
    }

    .inviteInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      .avatarBox {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        margin-bottom: 12px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .name {
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #363636;
      }
    }

    .contentBlock {
      padding: 0 20px;
      .contentTitle {
        font-size: 13px;
        line-height: 18px;
        color: #8F8F8F;
        margin-bottom: 8px;
      }
      .contentDesc {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #363636;
      }
      .memberList {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(auto-fit, 26px);
        gap: 8px;

        img {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }

    .divider {
      padding: 0 20px;
      height: 1px;
      background-color: #E3E3E3;
      margin-top: 27px;
      margin-bottom: 21px;
    }
  }

  .cancelRoot {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 522px;

    .cancelImg {
      width: 260px;
      img {
        width: 100%;
      }
    }

    .cancelText {
      margin-top: 16px;
      font-weight: 400;
      font-size: 15px;
      color: #B4B4B4;
    }
  }
}