.textBtn {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #CBCBCB;
  line-height: 22px;
  cursor: default;
}