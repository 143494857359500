.btn {
  width: 100%;
  padding: 15px 0px 14px 0px;
  text-align: center;

  background: #1dd2c1;

  font-size: 15px;

  font-weight: 500;
  color: #ffffff;
  line-height: 21px;

  &:hover {
    background: #61dfd3;
  }
}

.btn_wx {
  width: 100%;

  wx-open-launch-weapp {
    display: block;
    width: 100%;
    height: 48px;
    // padding: 15px 0px 14px 0px;
    padding: 13px 0px 14px 0px;
    text-align: center;

    background: #1dd2c1;

    font-size: 15px;

    font-weight: 500;
    color: #ffffff;
    line-height: 21px;

    &:hover {
      background: #61dfd3;
    }
  }
}
