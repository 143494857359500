

.card{

  width: 343px;
  // height: 160px;
  background: #FFFFFF;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
}