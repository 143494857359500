.container {
  background-image: url("../../images/menber_backgroup.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .box_wrap {
    margin-top: 146px;

    display: flex;
    align-items: center;
    flex-direction: column;

    .box {
      background-color: #fff;
      width: 429px;
      border-radius: 8px;
      padding: 36px 32px;
      background-image: url("../../images/mask_group.png");
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
      .header {
        display: flex;
        align-items: center;
        .title {
          font-weight: bold;
          font-size: 22px;
          color: #1dc6b6;
          margin-left: 8px;
        }
        .desc {
          font-weight: 400;
          font-size: 14px;
        }
      }

      .list_wrap {
        border: 1px solid #efefef;
        border-radius: 6px;
        margin-top: 21px;
        height: 429px;
        display: flex;
        flex-direction: column;

        .list_title {
          padding: 24px 24px 20px 24px;
          box-sizing: border-box;
          font-size: 15px;
          line-height: 14px;
          color: #474747;
        }
        .empty {
          display: block;
          padding-top: 128px;
          width: 100%;
          text-align: center;
          flex: 1;
          font-size: 14px;
          color: #8f8f8f;
          font-family: "PingFang SC";
          font-style: normal;
        }

        .list {
          flex: 1;
          overflow-y: auto;
          padding-left: 24px;
          .item {
            height: 40px;
            display: flex;
            align-items: center;
            .avatar {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
            .name {
              font-size: 14px;
              color: #363636;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
  .icon {
    width: 32px;
    height: 32px;

    background: #33d7c8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    width: 253px;
    background: linear-gradient(44.77deg, #1dd2c1 25.36%, #00e09d 74.98%);
    border-radius: 8px;
    height: 48px;
    margin-top: 66px;
    color: #ffffff;
    font-size: 16px;
  }
}
