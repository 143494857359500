.timeBox {
  display: flex;
  font-size: 12px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.4);
  .iconBox {
    display: flex;
    margin-left: 12px;
    img {
      width: 16px;
      height: 14px;
      margin-right: 5px;
    }
  }
}