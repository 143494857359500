.custom_popover {
  :global {
    .ant-popover-arrow {
      display: none;
    }
  }
}

.takers-taker {
  // padding: 0 0 0 0;
  padding-bottom: 20px;
  width: calc(100% + 26px);
  line-height: 0px;

  &__cell {
    display: inline-block;
    position: relative;
    text-align: center;
    // width: 40px;
    width: 46px;
    // width: 66px;
    margin-top: 19px;
    max-height: 75px;

    // 两个头像间间距
    // margin-right: 27px;
    margin-right: 12px;
    font-size: 13px;
  }

  &__img {
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;

    button {
      padding: 0;
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    // 小角标
    &__spicon {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 25px;
      left: 27px;
    }
  }

  &__icon {
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    background: none;
    border: none;
    margin: 0 0 0 1px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__icon_CanNotAdd {
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    border: none;
    margin: 0 0 0 1px;
    background: rgba(216, 216, 216, 0.4);

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  button:hover {
    // opacity: 0.7;
    background: rgba(216, 216, 216, 0.4);
    transition: all 0.1s;
  }

  button:focus,
  button:active:focus,
  button.active:focus,
  button.focus,
  button:active.focus,
  button.active.focus {
    outline: none;
    border-color: transparent; // 若是本身具有 border 请删除此属性
    // box-shadow: none;
  }

  // 角色昵称
  &__name {
    width: 100%;
    height: 20px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 13px;
    font-weight: 400;
    color: #060606;
    line-height: 26px;
  }

  // 角色接受状态
  &__state {
    width: 100%;
    height: 36px;
    display: block;
    white-space: nowrap;
    // text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 14px;
    font-size: 12px;
    // font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #060606;
  }

  &__right {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 480px;

    &__list {
      overflow: auto;
    }
  }
}
